import axios from "axios";
import jwt_decode from "jwt-decode";
import SignerService from "./service/SignerService.js";

(function (window) {
  function initIIRISProfileMgr() {

    let profileMgrObj = {

      //function to refresh auth token every 30 min
      getProfileByRefreshingAuth() {

        const passToken = this.getIIRISCookie ? this.getIIRISCookie("IRIS_HASH") : this.IIRISProfileMgr.getIIRISCookie("IRIS_HASH");
        const authToken = this.getIIRISCookie ? this.getIIRISCookie("IRIS_AUTH") : this.IIRISProfileMgr.getIIRISCookie("IRIS_AUTH");

        if (authToken && passToken) {
          //step1:Get the Refreshed token using API
          //step2:Set the Auth Token
          //step3:Get the Profile only for the first time when script is loaded
          if (this.getRefreshedAuth && this.setIIRISCookie && this.getIIRISCookie) {
            this.getRefreshedAuth(passToken, authToken, this.setIIRISCookie, this.getIIRISCookie);
          }
          else {
            this.IIRISProfileMgr.getRefreshedAuth(passToken, authToken, this.IIRISProfileMgr.setIIRISCookie, this.IIRISProfileMgr.getIIRISCookie);
          }
        }

        setInterval(this.getProfileByRefreshingAuth, 1800000); //call every 30 mins
      },

      //function to get refreshAuth token using API
      getRefreshedAuth(passToken, authToken, setCookie, getCookie) {
        let isLinkedInLogin = false;
        let isSocialLogin = false;
        let email = "";
        const apiDomain = process.env.API_BASE_URL;
        const audienceApiDomain = process.env.AUDIENCE_CONSENT_API_BASE_URL
        const idToken = getCookie("IRIS_IDT");
        const payload = {};

        if (idToken) {
          const decodedToken = jwt_decode(idToken);
          if (decodedToken.email.includes("linkedin.")) {
            isLinkedInLogin = true;
            email = decodedToken.email.replace("linkedin.", "");
          }
          else if (decodedToken.identities) {
            isSocialLogin = true;
          }
        }

        if (isLinkedInLogin) {
          payload["linkedin_email"] = email;
        } else if (isSocialLogin) {
          payload["client_id"] = process.env.COGNITO_WEBCLIENT_ID;
          payload["refresh_token"] = passToken;
        } else {
          payload["refresh_token"] = passToken;
        }

        try {
          const url = apiDomain + `/v2.1/authorization/public/refreshAuthToken${isLinkedInLogin ? `?m2m=true` : ''}`;
          const result = SignerService.signedAPIRequest(
            "execute-api",
            "POST",
            url,
            payload
          ).then(function (response) {
            const refreshedAuthToken = response.access_token;
            const authFlow = localStorage.getItem('auth_flow') ? localStorage.getItem('auth_flow') : 'anonymous';
            //Set the cookie
            setCookie("IRIS_AUTH", refreshedAuthToken);

            //Get the Profile
            const irisId = getCookie("IRIS_ID");
            const authToken = getCookie("IRIS_AUTH");
            const appId = getCookie("IRIS_APPID");
            if (irisId && authToken) {
              axios
                .get(audienceApiDomain + "/v3/audience/profile/" + irisId + "/"+ appId, {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + authToken,
                  },
                })
                .then((audResponse) => {
                  if (audResponse.status === 200) {
                    let userObj = audResponse.data.data[0];
                    userObj.profile_picture = localStorage.getItem("profile_picture") ? localStorage.getItem("profile_picture") : ""
                    userObj.is_social_login = JSON.parse(localStorage.getItem("is_social_login"))
                    if (window.irisProfileMgrCallback) {
                      const callbackObj = { "success": true, "user": userObj, "token": authToken }
                      window.irisProfileMgrCallback(callbackObj);
                    }
                  } else {
                    if (window.irisProfileMgrCallback) {
                      const callbackObj = { "success": false, "user": null, "token": null }
                      window.irisProfileMgrCallback(callbackObj);
                    }
                  }
                })
                .catch((e) => {
                  if (window.irisProfileMgrCallback) {
                    const callbackObj = { "success": false, "user": null, "token": null }
                    window.irisProfileMgrCallback(callbackObj);
                  }
                });
            } else {
              if (window.irisProfileMgrCallback) {
                const callbackObj = { "success": false, "user": null, "token": null }
                window.irisProfileMgrCallback(callbackObj);
              }
            }

          })
            .catch((e) => {
              if (window.irisProfileMgrCallback) {
                const callbackObj = { "success": false, "user": null, "token": null }
                window.irisProfileMgrCallback(callbackObj);
              }
            });
          return result;
        } catch (error) {
          console.log("error", error);
          return error.response;
        }
      },

      //function to get taxonomy data by passing QuestionID or QuestionPath, Division and Brand
      getTaxonomyData(path) {
        const apiDomain = process.env.API_BASE_URL;

        return SignerService.signedAPIRequest(
          'execute-api',
          'GET',
          apiDomain + '/v2/taxonomy/public/' + path,
          ''
        ).then((data) => {
          return data;
        }).catch((error) => {
          console.log("error", error);
          return error.response;
        });
      },

      //function to fetch Audience Profile using Audience API 
      getIIRISProfile() {
        this.getProfileByRefreshingAuth();
      },

      //Profile POST 
      setIIRISProfile(userObj) {
        const apiDomain = process.env.AUDIENCE_CONSENT_API_BASE_URL;
        const authToken = this.getIIRISCookie("IRIS_AUTH");
        const irisId = this.getIIRISCookie("IRIS_ID");
        const appId = this.getIIRISCookie("IRIS_APPID");
        try {
          const url = apiDomain + "/v3/audience/profile/update/"+irisId+"/"+appId;
          const jwtToken = authToken;
          const options = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Authorization": jwtToken,
            },
            data: userObj,
            url,
          };
          const result = axios(options).then(function (response) {
            let responseData = response.data;
            const isSocialLoginStr = localStorage.getItem('is_social_login');
            if (isSocialLoginStr) {
              responseData.data[0].is_social_login = JSON.parse(isSocialLoginStr);
            }
            return responseData;
          });
          return result;
        } catch (error) {
          console.log("error", error);
          return error.response;
        }
      },

      //GET Registration Details 
      getIIRISRegistrationDetails(productId) {
        const apiDomain = process.env.AUDIENCE_CONSENT_API_BASE_URL;
        const authToken = this.getIIRISCookie("IRIS_AUTH");
        const irisId = this.getIIRISCookie("IRIS_ID");
        try {
          const url = `${apiDomain}/v3/audience/registration/${irisId}/${productId}`;
          const jwtToken = authToken;
          const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": jwtToken,
            },
            url,
          };
          const result = axios(options).then(function (response) {
            return response.data;
          });
          return result;
        } catch (error) {
          console.log("error", error);
          return error.response;
        }
      },

      //GET Consent Details 
      getIIRISConsent(version) {
        const apiDomain = process.env.AUDIENCE_CONSENT_API_BASE_URL;
        const authToken = this.getIIRISCookie("IRIS_AUTH");
        const irisId = this.getIIRISCookie("IRIS_ID");
        try {
          const url = `${apiDomain}/v3/consent/data/iid/${irisId}/${version}`;
          const jwtToken = authToken;
          const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": jwtToken,
            },
            url,
          };
          const result = axios(options).then(function (response) {
            return response.data;
          });
          return result;
        } catch (error) {
          console.log("error", error);
          return error.response;
        }
      },

      //function to set/update auth in cookie
      setIIRISCookie(cName, cValue) {
        document.cookie =
          cName + "=" + cValue + "; path=/; max-age=7776000; samesite=strict; secure; domain=" + window.location.hostname;
      },

      //function to fetch/get cookie by cookie name
      getIIRISCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },

      getGuestSession() {
        const region = process.env.COGNITO_REGION;
        const identityId = process.env.COGNITO_IDENTITY_ID;
        const cognitoIdentityURL =
          "https://cognito-identity." + region + ".amazonaws.com/";
        const credsPayload = { IdentityId: identityId };
        const headers = {
          "Content-Type": "application/x-amz-json-1.1",
          "X-Amz-Target": "AWSCognitoIdentityService.GetCredentialsForIdentity",
        };
        const access_info = axios
          .post(cognitoIdentityURL, credsPayload, { headers })
          .then(function (response) {
            return {
              access_key: response.data.Credentials.AccessKeyId,
              secret_key: response.data.Credentials.SecretKey,
              session_token: response.data.Credentials.SessionToken,
              expiration: response.data.Credentials.Expiration,
              region: region,
            };
          })
          .catch(function (error) {
            console.log(error);
          });
        return access_info;
      }
    };
    return profileMgrObj;
  }

  if (typeof window.IIRISProfileMgr === "undefined") {
    window.IIRISProfileMgr = initIIRISProfileMgr();
  }
  window.IIRISProfileMgr.getIIRISProfile();
})(window);
